<template>
    <div>
        <ContextBar :title="$t('main.reports')">
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="reports" ref="dataTable" :columns="columns" :parameters="parameters" :selectable="true"
            :key="refresh">
            <template #with_selected_ids="{ ids }">
                <div class="menu-item text-error">
                    <a href="#" @click.prevent="deleteReports(ids)">{{ $t('reports.delete_reports', ids.length) }}</a>
                </div>
            </template>

            <template #column(name)="{ value }">{{ value }}</template>
            <template #column(layout)="{ item }">{{ item.template.name }}</template>
            <template #column(files)="{ item }">
                <span class="chip chip-lg mr-1" v-for="file in item.files">
                    <router-link :to="{ name: 'files.show', params: { id: file.id } }">
                        {{ file.scan_code }}
                    </router-link>
                </span>
            </template>

            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>

            <template #column(status)="{ item }">
                <span v-if="item.failed" class="text-error tooltip" :data-tooltip="$t('main.oops')">
                    <i class="fas fa-exclamation-triangle mr-2"></i>
                    <span>{{ $t('main.download') }}</span>
                </span>

                <a v-else :href="item.url" target="_blank" class="btn btn-primary"
                    :class="{ loading: !item.created }">{{ $t('main.download') }}</a>
            </template>

            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right">
                    <div class="menu-item">
                        <a href="#" @click.prevent="regenerateReport(item)">{{ $t('files.create_report') }}</a>
                    </div>
                    <div class="menu-item text-error">
                        <a href="#" @click.prevent="deleteReport(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>
        </DataTable>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,

            timer: null,
            search: this.$route.query.search,

            columns: [
                { name: 'name', th: 'main.name', sortable: true },
                { name: 'layout', th: 'reports.layout', sortable: true },
                { name: 'files', th: 'reports.files' },
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'status', th: 'reports.status' },
                { name: 'actions', min: true },
            ],
        }
    },

    computed: {
        parameters() {
            return { search: this.search || undefined }
        },
    },

    mounted() {
        this.timer = setInterval(this.checkReportsReady, 3000)
    },

    beforeUnmount() {
        clearInterval(this.timer)
    },

    methods: {
        async checkReportsReady() {
            const reportsToCheck = this.$refs.dataTable.paginator.data.filter(item => !item.created && !item.failed)

            if (!reportsToCheck.length) return

            try {
                const ids = reportsToCheck.map(r => r.id)
                const { data } = await this.$axios.get('reports/check_ready', { params: { ids } })

                data.forEach(reportCompleted => {
                    const report = reportsToCheck.find(r => r.id === reportCompleted.id)

                    if (report) Object.assign(report, reportCompleted)
                })
            } catch { }
        },

        async regenerateReport(report) {
            if (report.regenerating) return

            report.regenerating = true
            await this.$axios.post(`reports/${report.id}/regenerate`)
            window.toast(this.$root.$t('reports.being_generated'))
            this.refresh++
        },

        deleteReports(ids) {
            this.$swal({
                input: 'text',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                title: this.$t('reports.delete_reports', ids.length),
                confirmButtonText: this.$t('reports.delete_reports', ids.length),
                inputPlaceholder: this.$t('reports.type_the_number_of_reports_to_delete'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: value => {
                    if (value == ids.length) return this.$axios.delete(`reports/${ids.join(',')}`)

                    this.$swal.showValidationMessage(this.$t('reports.type_the_number_of_reports_to_delete'))
                },
            }).then(({ value }) => value && this.refresh++)
        },

        deleteReport(report) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`reports/${report.id}`).then(() => this.refresh++)
                }
            })
        },
    },
}
</script>
