<template>
    <div>
        <ContextBar :title="$t('main.webhooks')" :buttonText="$t('context.create_webhooks')"
            buttonAbility="Use global settings" buttonRoute="settings.webhooks.create"></ContextBar>

        <DataTable endpoint="webhooks" :columns="columns" :key="refresh">
            <template #context-left>
                <span class="mr-2">{{ $t('settings.api_tokens_to_consume_data') }}</span>
                <a href="https://developer.cargosnap.com" target="_blank">{{ $t('api_tokens.documentation') }}</a>
            </template>
            <template #column(description)="{ item }">
                <router-link :to="{ name: 'settings.webhooks.show', params: { id: item.id } }" class="table-link">
                    <span>{{ item.description }}</span>
                </router-link>
            </template>
            <template #column(action)="{ value }">{{ $t(`webhooks.${value}`) }}</template>
            <template #column(paused)="{ value }">
                <span v-if="value">
                    {{ $t('webhooks.paused') }}
                    <span class="ml-1 tooltip" :data-tooltip="$t('webhooks.paused_info')">
                        <i class="fas fa-info-circle"></i>
                    </span>
                </span>
                <span v-else>{{ $t('webhooks.running') }}</span>
            </template>
            <template #column(email_on_failure)="{ value }">
                <div v-for="email in value.split(',')" :key="email">{{ email }}</div>
            </template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right">
                    <div class="menu-item">
                        <router-link :to="{ name: 'settings.webhooks.show', params: { id: item.id } }">
                            {{ $t('main.edit') }}
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <a href="#" class="text-error" @click.prevent="deleteWebhook(item)">
                            {{ $t('main.delete') }}
                        </a>
                    </div>
                </Dropdown>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,
            columns: [
                { name: 'description', th: 'webhooks.description', sortable: true },
                { name: 'action', th: 'webhooks.action', sortable: true },
                { name: 'paused', th: 'webhooks.status', sortable: true },
                { name: 'email_on_failure', th: 'webhooks.email', sortable: true },
                { name: 'actions', min: true },
            ],
        }
    },

    methods: {
        deleteWebhook(token) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`webhooks/${token.id}`).then(() => this.refresh++)
                }
            })
        },
    },
}
</script>
