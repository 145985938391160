<template>
    <Modal :title="$t('files.download_images_as_zip')" @close="back()">
        <Errors :errors="errors" />

        <template #footer>
            <SaveButton @click="downloadZip()">{{ $t('main.download') }}</SaveButton>
        </template>
    </Modal>
</template>

<script>
import { downloadFile } from '~/utils'

export default {
    data() {
        return {
            errors: null,
            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,
        }
    },

    methods: {
        back() {
            if (this.$route.params.id) {
                this.$router.push({ name: 'files.show' })
            } else {
                this.$router.push({ name: 'files' })
            }
        },

        async downloadZip() {
            try {
                const { data } = await this.$axios.post('files/create_zip_url', { files: this.ids })
                downloadFile(data)
                this.back()
            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
