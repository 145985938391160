<template>
    <div>
        <ContextBar :title="$t('main.users')" :buttonText="$t('context.create_users')" buttonAbility="Create users"
            buttonRoute="settings.users.create">
            <label class="form-checkbox">
                <input type="checkbox" v-model="show_deleted" />
                <i class="form-icon"></i>
                <span>{{ $t('users.show_deleted_users') }}</span>
            </label>

            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="users" :columns="columns" :parameters="parameters" sort="last_login" :key="refresh">
            <template #column(name)="{ item }">
                <div class="flex-start">
                    <Avatar :initials="item.name"></Avatar>
                    <router-link :to="{ name: 'settings.users.show', params: { id: item.id } }" style="flex: 1"
                        class="table-link ml-2">
                        {{ item.name }}
                    </router-link>
                </div>
            </template>
            <template #column(location_id)="{ item }">{{ getLocationName(item.location_id) }}</template>
            <template #column(email)="{ value }">{{ value }}</template>
            <template #column(roles)="{ value }">
                <div v-for="role in value" :key="role.id">
                    <span v-if="role.name !== 'Free'">{{ $t(`user_roles.${role.name}`) }}</span>
                </div>
            </template>
            <template #column(last_login)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
        </DataTable>

        <router-view @refresh="refresh++" :locations="locations"></router-view>
    </div>
</template>

<script>
import Avatar from '~/components/Avatar.vue'

export default {
    components: { Avatar },

    data() {
        return {
            refresh: 0,
            locations: [],

            search: this.$route.query.search,
            location_id: this.$route.query.location_id,
            show_deleted: this.$route.query.show_deleted,

            columns: [
                { name: 'name', th: 'users.name', sortable: true, class: 'mw-250' },
                { name: 'location_id', th: 'main.location', sortable: true },
                { name: 'email', th: 'users.email', sortable: true },
                { name: 'roles', th: 'users.role' },
                { name: 'last_login', th: 'users.last_login', sortable: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                location_id: this.location_id || undefined,
                show_deleted: this.show_deleted || undefined,
            }
        },
    },

    async mounted() {
        const { data: paginator } = await this.$axios.get('locations', {
            params: { sort: 'location', by: 'asc', limit: 200 },
        })

        this.locations = paginator.data
    },

    methods: {
        getLocationName(locationId) {
            return this.locations.find(l => l.id === locationId)?.location
        },
    },
}
</script>
