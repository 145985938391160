<template>
    <VueSelect v-model="value" :label="label" :options="filteredOptions">
        <template #selected-option="option">
            <Avatar v-if="avatar" :initials="option[label]" class="avatar-sm"></Avatar>
            <div>{{ option[label] }}</div>
        </template>
        <template #option="option">
            <div class="flex-space" style="width: fit-content">
                <Avatar v-if="avatar" :initials="option[label]" class="avatar-sm mr-2"></Avatar>
                <div>{{ option[label] }}</div>
            </div>
        </template>
        <template #no-options>{{ $t('fields.select_no_options') }}</template>
        <template #search="{ attributes, events }">
            <input class="vs__search" :required="isRequired" v-bind="attributes" v-on="events" />
        </template>
    </VueSelect>
</template>

<script>
import VueSelect from 'vue-select'
import Avatar from '~/components/Avatar.vue'
import CloseIcon from './icons/CloseIcon.vue'
import OpenIcon from './icons/OpenIcon.vue'

VueSelect.props.components.default = () => ({ Deselect: CloseIcon, OpenIndicator: OpenIcon })

export default {
    props: {
        modelValue: {
            type: [String, Number, Array, Object],
        },
        label: {
            type: String,
            default: 'label',
        },
        avatar: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue'],

    components: { VueSelect, Avatar },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

        isRequired() {
            return this.required && !this.value?.length
        },

        filteredOptions() {
            return this.options.filter(Boolean)
        }
    },
}
</script>