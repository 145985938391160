import axios from 'axios'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'

const baseURL = import.meta.env.VITE_API_BASE_URL
const axiosInstance = axios.create({ baseURL, timeout: 30000 })
const pendingRequests = new Map()

const getRequestIdentifier = config => `${config.method}_${config.url}`

axiosInstance.interceptors.request.use(config => {
    if (store.state.jwt) {
        config.headers['Accept-Language'] = store.state.language
        config.headers['Authorization'] = `Bearer ${store.state.jwt.token}`
    }

    const requestIdentifier = getRequestIdentifier(config)

    // cancel previous duplicate request
    if (pendingRequests.has(requestIdentifier)) {
        pendingRequests.get(requestIdentifier).cancel()
    }

    const source = axios.CancelToken.source()
    config.cancelToken = source.token

    if (requestIdentifier === 'get_files') {
        // TODO: remove temp hack: send individual fields as json, need to remove this when backend is ready
        if (config.params.fields) config.params.fields = config.params.fields.map(field => JSON.stringify(field))
    }

    // store the new cancel token source in the map
    pendingRequests.set(requestIdentifier, source)

    return config
})

axiosInstance.interceptors.response.use(
    response => {
        const requestIdentifier = getRequestIdentifier(response.config)
        pendingRequests.delete(requestIdentifier)

        return response
    },
    async (error) => {
        const status = error.response?.status

        if (status === 401 || status === 402) {
            if (store.state.loggedIn) {
                window.toast(i18n.global.t('auth.session_expired'), 'info', 5000)
            }

            await store.dispatch('endSession')
            return new Promise(() => { })
        }

        if (status === 403) {
            window.toast(i18n.global.t('main.no_permission'), 'error')
        }

        if (status === 404 || status === 405) {
            window.toast(i18n.global.t('main.not_found'), 'error')

            // UX: Go to the parent route if exists
            const routeName = router.currentRoute.value.name
            const parentRoute = routeName.substring(0, routeName.lastIndexOf('.'))

            if (router.hasRoute(parentRoute)) {
                router.replace({ name: parentRoute })
            }
        }

        if (status >= 500) {
            window.toast(i18n.global.t('main.oops'), 'error')
        }

        return Promise.reject(error)
    }
)

export default axiosInstance
