<template>
    <Spinner></Spinner>
</template>

<script>
export default {
    async mounted() {
        await this.$axios.post(`auth/activate/${this.$route.params.token}`)
        await this.$store.dispatch('fetchUser')

        if (this.hasNotStartedTour && this.companyIsInTrial && this.isLicenseAdmin) {
            this.$store.dispatch('startTour')
        }

        window.toast(this.$root.$t('auth.account_activated'))
        this.$router.replace({ name: 'dashboard' })
    },

    computed: {
        hasNotStartedTour() {
            return !this.$store.state.user.company.properties?.onboarding
        },

        companyIsInTrial() {
            return this.$store.state.jwt.status === 'trial'
        },

        isLicenseAdmin() {
            return this.$store.state.user.roles.includes('LicenseAdmin')
        }
    }
}
</script>
