<template>
    <div>
        <ContextBar :title="$t('share.outgoing')">
            <DebounceInput v-model="search" :placeholder="$t('files.search_on_reference')" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="sharing_out" :columns="columns" :parameters="parameters" :key="refresh" hasFilters>
            <template #context-left>
                <router-link tag="button" class="btn" :to="{ name: 'sharing' }" exact>
                    {{ $t('share.incoming') }}
                </router-link>
                <router-link tag="button" class="btn" :to="{ name: 'sharing.out' }">
                    {{ $t('share.outgoing') }}
                </router-link>
            </template>
            <template #context-right>
                <DatePicker v-model="date_range" :placeholder="$t('files.created_between')" range multi-calendars>
                </DatePicker>
                <input v-model="email" class="form-input" :placeholder="$t('share.recipients')" />
                <label class="form-checkbox">
                    <input type="checkbox" v-model="shared_by_me" />
                    <i class="form-icon"></i>
                    <span>{{ $t('share.by_me') }}</span>
                </label>
            </template>

            <template #column(shared_by)="{ value }">{{ value }}</template>
            <template #column(scan_code)="{ item }">
                <FileReference :id="item.file_id" :reference="item.scan_code" :closed_at="item.closed_at">
                </FileReference>
            </template>
            <template #column(email)="{ value }">{{ value }}</template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(expiry_date)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right">
                    <div class="menu-item">
                        <a :href="`/share/${item.token}`" target="_blank">{{ $t('files.view') }}</a>
                    </div>
                    <div class="menu-item text-error">
                        <a href="#" @click.prevent="deleteShare(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>
        </DataTable>
    </div>
</template>

<script>
import FileReference from '~/components/FileReference.vue'

export default {
    components: { FileReference },

    data() {
        return {
            refresh: 0,

            search: this.$route.query.search,
            email: this.$route.query.email,
            shared_by_me: this.$route.query.shared_by_me,
            date_range: this.$route.query.date_range,

            columns: [
                { name: 'shared_by', th: 'share.shared_by', sortable: true },
                { name: 'scan_code', th: 'files.reference', sortable: true, class: 'mw-250' },
                { name: 'email', th: 'share.recipients', sortable: true },
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'expiry_date', th: 'main.expiry', sortable: true },
                { name: 'actions', min: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                email: this.email || undefined,
                shared_by_me: this.shared_by_me || undefined,
                date_range: this.date_range || undefined,
            }
        },
    },

    methods: {
        deleteShare(share) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`files/${share.file_id}/shares/${share.id}`).then(() => this.refresh++)
                }
            })
        },
    },
}
</script>
