import axios from '~/plugins/axios'
import i18n from '~/plugins/i18n.js'

export const getFieldAnswer = (field, answers) => {
    const answer = answers.find(a => a.form_field_id === field.id)

    if (!answer) return

    const value = answer.value

    // add thumbnail to field to display in form
    if (field.type === 'snap' || field.type === 'drawing') {
        field.image_thumb_sm = answer.image_thumb_sm
    }

    // for type single_select: parse db string to array
    if (field.type === 'single_select' && value.startsWith('[') && value.endsWith(']')) {
        return JSON.parse(value)
    }

    return value
}

export const transformFormFields = (fields, answers, displayEmptyValues) => {
    const filteredFields = []
    const nonFillableTypes = ['header', 'paragraph', 'separator']

    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const value = getFieldAnswer(field, answers)

        if (!field.show_in_platform) continue

        // check if answer is an array (multi-select) 
        field.value = Array.isArray(value) ? value.join(', ') : value

        const fieldIsEmpty = [undefined, ''].includes(field.value)
        const fieldIsFillable = !nonFillableTypes.includes(field.type)

        // skip fields that are deleted & empty
        if (field.deleted_at && fieldIsEmpty) continue

        // when displayEmptyValues is set to false, we want to skip fillable empty fields
        if (!displayEmptyValues && fieldIsEmpty && fieldIsFillable) continue

        filteredFields.push(field)
    }

    return filteredFields
}

export const deleteForm = async (form, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        title: i18n.global.t('main.are_you_sure'),
        text: i18n.global.t('main.you_wont_be_able_to_revert_this'),
        confirmButtonText: i18n.global.t('main.yes_delete_it'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    await axios.delete(`forms/${form.id}`)
}

export const duplicateForm = async (form, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        confirmButtonColor: '#0092c8',
        title: i18n.global.t('forms.duplicate_this_form'),
        confirmButtonText: i18n.global.t('main.confirm'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    const { data } = await axios.get(`forms/${form.id}`)

    Object.assign(form, data)

    const duplicated = JSON.parse(JSON.stringify(form))

    duplicated.title = duplicated.title + ' (Copy)'

    delete duplicated.id
    duplicated.fields.forEach(field => delete field.id)

    return await axios.post('forms', duplicated)
}