<template>
    <Modal :title="$t('files.add_field_to_file')" @close="back()">
        <div class="form-group">
            <label class="form-checkbox">
                <input v-model="updateExistingValues" type="checkbox" />
                <i class="form-icon"></i>
                <span>{{ $t('files.update_values_of_existing_fields') }}</span>
            </label>
        </div>

        <div class="form-group">
            <select v-model="fieldSelected" class="form-select">
                <option v-for="field in fields" :key="field.id" :value="field">{{ field.name }}</option>
            </select>
        </div>

        <div class="form-group">
            <FieldsModel v-if="fieldSelected" :field="fieldSelected" v-model="fieldSelected.value"></FieldsModel>
        </div>

        <template #footer>
            <SaveButton @click="save()" :disabled="!fieldSelected">{{ $t('main.create') }}</SaveButton>
            <button @click="back()" class="btn ml-2">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>

<script>
import FieldsModel from '~/components/files/FileFieldsModel.vue'

export default {
    components: { FieldsModel },

    data() {
        return {
            fields: [],
            fieldSelected: null,
            updateExistingValues: false,
            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,
        }
    },

    async mounted() {
        const { data: paginator } = await this.$axios.get('fields', {
            params: { limit: 200, sort: 'name', by: 'asc' },
        })

        this.fields = paginator.data
    },

    methods: {
        back() {
            if (this.$route.params.id) {
                this.$router.push({ name: 'files.show' })
            } else {
                this.$router.push({ name: 'files' })
            }
        },

        async save() {
            await this.$axios.post('files_fields', {
                files: this.ids,
                field_id: this.fieldSelected.id,
                field_value: this.fieldSelected.value || '',
                update_existing: this.updateExistingValues,
            })

            this.$emit('refresh')
            this.back()
        },
    },
}
</script>
