<template>
    <WatchForUnsavedChanges ref="watch" v-model="form">
        <Modal :title="form.title" @close="$router.push({ name: 'files.show' })">
            <form v-if="form.id" @submit.prevent="save()" ref="form">
                <FormFieldsModel v-for="field in form.fields" v-model="field.answer" :field="field" :label="field.label"
                    :required="field.required" class="form-group" bold>
                </FormFieldsModel>

                <input type="submit" hidden />
            </form>
            <div v-else class="loading loading-lg"></div>

            <template #footer v-if="form.id">
                <button class="btn btn-primary" :class="{ loading }" @click="$refs.form.requestSubmit()">
                    {{ $t('files.submit_form') }}
                </button>
            </template>
        </Modal>
    </WatchForUnsavedChanges>
</template>

<script>
import FormFieldsModel from '~/components/files/FileFormFieldsModel.vue'

export default {
    props: { file: Object },
    emits: ['refresh'],

    components: { FormFieldsModel },

    data() {
        return {
            form: {},
            loading: false,
            file_id: this.$route.params.id,
            form_id: this.$route.params.form_id,
        }
    },

    async mounted() {
        const { data: form } = await this.$axios.get(`forms/${this.form_id}`)

        form.fields.forEach(field => field.answer = field.options.default)

        this.form = form
        this.form.form_id = this.form_id
        this.form.file_id = this.file_id
    },

    methods: {
        async save() {
            if (this.loading) return

            this.loading = true
            
            await this.$axios.post('submits', this.form)

            this.$refs.watch?.init()
            this.$router.push({ name: 'files.show', params: { id: this.file_id } })
            this.$emit('refresh')
        },
    },
}
</script>
